import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { navigate } from "gatsby";
import {
  Avatar,
  Box,
  ButtonBase,
  Button,
  Divider,
  IconButton,
  List,
  ListItemButton,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ShoppingCart as ShoppingCartIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { VerticalAlignCenter } from "../../VerticalAlignCenter";
import { Logo } from "../../Logo";
import { MobileDrawer } from "../../MobileDrawer";

import { DASHBOARD } from "../../../constants/routes";

import { stringAvatar } from "../../../utils/index";

const BAR_HEIGHT = 97;

interface MainBarProps {
  isAuthenticated: boolean;
}

export const MainBar: React.FC<MainBarProps> = ({ isAuthenticated }) => {
  const { givenName, familyName } = useSelector(
    (state: any) => state.userReducer
  );
  const { signOut } = useAuthenticator();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const popoverIsOpen = Boolean(anchorEl);
  const id = popoverIsOpen ? "simple-popover" : undefined;

  const handleClickOpenPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickClosePopover = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    signOut();
  };

  const handleCartDrawerOpen = () => {
    dispatch(setCartDrawerIsOpen(true));
  };
  const handleCartDrawerClose = () => {
    dispatch(setCartDrawerIsOpen(false));
  };

  const handleClickNavigate = (path: string) => {
    navigate(path);
    setAnchorEl(null);
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex">
        {isMobile ? (
          <>
            {/* <IconButton
              color="secondary"
              edge="end"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton> */}
            <IconButton color="inherit" edge="end">
              <Logo />
            </IconButton>
            <MobileDrawer
              open={drawerOpen}
              handleClose={handleDrawerToggle}
              customerName={`${familyName} ${givenName}`}
              navigate={navigate}
              handleLogout={handleLogout}
            />
          </>
        ) : (
          <Box display="flex">
            <VerticalAlignCenter height={BAR_HEIGHT}>
              <Logo />
            </VerticalAlignCenter>
            <VerticalAlignCenter height={BAR_HEIGHT}>
              <Box ml={2}>
                <ButtonBase onClick={() => navigate("/")}>
                  <Typography
                    variant="h1"
                    color="primary"
                    sx={{ fontSize: 28 }}
                  >
                    ComplyHub
                  </Typography>
                </ButtonBase>
              </Box>
            </VerticalAlignCenter>
          </Box>
        )}
      </Box>
      <Box display="flex">
        {isAuthenticated ? (
          <Box display="flex">
            <Box>
              <VerticalAlignCenter height={BAR_HEIGHT}>
                <IconButton size="small" onClick={handleClickOpenPopover}>
                  {familyName && (
                    <Avatar {...stringAvatar(`${familyName} ${givenName}`)} />
                  )}
                </IconButton>
              </VerticalAlignCenter>
              <Popover
                id={id}
                open={popoverIsOpen}
                anchorEl={anchorEl}
                onClose={handleClickClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <List>
                  <ListItemButton
                    onClick={() => handleClickNavigate(DASHBOARD)}
                  >
                    Dashboard
                  </ListItemButton>

                  <Divider />
                  <ListItemButton onClick={handleLogout}>Logout</ListItemButton>
                </List>
              </Popover>
            </Box>
          </Box>
        ) : (
          <Box>
            <VerticalAlignCenter height={BAR_HEIGHT}>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={() => navigate(DASHBOARD)}
              >
                Login
              </Button>
            </VerticalAlignCenter>
          </Box>
        )}
      </Box>
    </Box>
  );
};
