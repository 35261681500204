export const ABOUT = `/about`;
export const CONTACT = `/contact`;
export const CONDO_ASSOCIATIONS = `/condo-associations`;
export const CREATE_CA = `${CONDO_ASSOCIATIONS}/create-ca`;
export const DASHBOARD = `/dashboard`;
export const FEATURES = `/features`;
export const HOME = `/`;
export const LOGIN = `/login`;
export const MY_ACCOUNT = `/my-account`;
export const OUR_TEAM = `/our-team`;
export const PRIVACY_POLICY = `/privacy-policy`;
export const USER_MANAGEMENT = `/user-management`;
export const CREATE_USER = `${USER_MANAGEMENT}/create-user`;
