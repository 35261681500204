import React from "react";
import { Box } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { Header } from "../Header";
import { Footer } from "../Footer";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { authStatus } = useAuthenticator();
  const authenticated = authStatus === "authenticated";
  return (
    <Box display="flex" flexDirection={`column`} height={`100vh`}>
      {!authenticated && <Header />}
      <Box component={`main`} flexGrow={1}>
        <Box>{children}</Box>
      </Box>
      <Footer />
    </Box>
  );
};
