import React from "react";
import { navigate } from "gatsby";
import { Box, ButtonBase, useMediaQuery, useTheme } from "@mui/material";

import LogoImg from "../../images/logo.png";
import LogoImgWhite from "../../images/logo-white.png";

interface LogoProps {
  isWhite?: boolean;
}

export const Logo: React.FC<LogoProps> = ({ isWhite }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ButtonBase onClick={() => navigate("/")}>
      <Box
        component="img"
        src={isWhite ? LogoImgWhite : LogoImg}
        height={isSmall ? `50px` : `60px`}
        alt="complyhub logo"
      />
    </ButtonBase>
  );
};
