import { createSlice } from "@reduxjs/toolkit";

function formatString(s: string): string {
  // Split the string by slashes and hyphens, filter out empty strings
  const parts = s.split(/[-\/]+/).filter(Boolean);
  // Capitalize the first letter of each part and join them with spaces
  const formattedString = parts
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(" ");
  return formattedString;
}
// Define the initial state
export interface AppState {
  currentPage: string | null;
}

const initialState: AppState = {
  currentPage: null,
};

export const breadcrumb = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      const isDashboardRoute = action.payload === "/dashboard";
      state.currentPage = isDashboardRoute ? "" : formatString(action.payload);
    },
  },
});

export const { setCurrentPage } = breadcrumb.actions;

export default breadcrumb.reducer;
