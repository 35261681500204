import { ThemeOptions, createTheme } from "@mui/material/styles";
import { lighten, darken } from "polished";

import {
  primaryColor,
  secondaryColor,
  fontFamilies,
  headlineFont,
  headlineFontStyles,
  commonColorStyles,
  bodyFont,
  customColorStyles,
} from "./theme.config";

// A custom theme for this app
export const theme: ThemeOptions = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#0f2c3e",
    },
    secondary: {
      main: "#40994b",
    },
  },
  typography: {
    h1: {
      fontFamily: "PT Sans",
    },
    h2: {
      fontFamily: "PT Sans",
    },
    h3: {
      fontFamily: "PT Sans",
    },
    h4: {
      fontFamily: "PT Sans",
    },
    h5: {
      fontFamily: "PT Sans",
    },
  },
});

export const darkTheme: ThemeOptions = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#0f2c3e",
    },
    secondary: {
      main: "#40994b",
    },
  },
  typography: {
    h1: {
      fontFamily: "PT Sans",
    },
    h2: {
      fontFamily: "PT Sans",
    },
    h3: {
      fontFamily: "PT Sans",
    },
    h4: {
      fontFamily: "PT Sans",
    },
    h5: {
      fontFamily: "PT Sans",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label": {
            color: "white",
          },
          "& label.Mui-focused": {
            color: "#40994b",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#40994b",
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            // Target the check icon
            color: "white", // Set the icon color to white for checked state
          },
        },
        label: {
          color: "white",
        },
      },
    },
  },
});
