import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./reducers/user.reducer";
import breadcrumbReducer from "./reducers/breadcrumb.reducer";

export const store = configureStore({
  reducer: {
    userReducer,
    breadcrumbReducer,
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});
