import React from 'react';
import { Helmet } from 'react-helmet';
import {
  appName,
  metaDescription,
  metaKeywords,
  appTitle,
  siteUrl,
  twitterHandle,
} from '../../config/site.config';
import { fontCdn } from '../../config/theme/fonts';

export const AppHelmet: React.FC = () => {
  return (
    <Helmet>
      <title>{`${appTitle}`}</title>
      <link rel="stylesheet" href="https://use.typekit.net/dua5kwu.css"></link>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link href={fontCdn} rel="stylesheet" />

      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge" />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="author" content={appName} />

      <meta property="og:title" content={appTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={`${siteUrl}/og-image.jpg`} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={appName} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={appTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={`${siteUrl}/twitter-image.jpg`} />
      <meta name="twitter:site" content={twitterHandle} />
      <meta name="twitter:creator" content={twitterHandle} />

      <link rel="canonical" href={siteUrl} />
      <link rel="icon" href="/favicon.ico" />
      <meta name="theme-color" content="#00154A"></meta>
    </Helmet>
  );
};
