import { createSlice } from '@reduxjs/toolkit';
// Define the initial state
export interface AppState {
  email: string | null;
  familyName: string | null;
  givenName: string | null;
  phoneNumber: string | null;
  role: string[];
  isAdmin: boolean;
  sub: string | null;
}

const initialState: AppState = {
  email: null,
  familyName: null,
  givenName: null,
  phoneNumber: null,
  role: [],
  isAdmin: false,
  sub: null,
};

function getIsAdmin(array: string[]) {
  return array.includes('admin');
}

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setSub: (state, action) => {
      state.sub = action.payload;
    },
    setFamilyName: (state, action) => {
      state.familyName = action.payload;
    },
    setGivenName: (state, action) => {
      state.givenName = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
      state.isAdmin = getIsAdmin(action.payload);
    },
  },
});

export const {
  setEmail,
  setSub,
  setFamilyName,
  setGivenName,
  setPhoneNumber,
  setRole,
} = user.actions;

export default user.reducer;
