import React, { useState, useEffect } from "react";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useDispatch } from "react-redux";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { AppBar, Box, Container, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TopBar } from "./TopBar";
import { MainBar } from "./MainBar";

import {
  setEmail,
  setSub,
  setFamilyName,
  setGivenName,
  setPhoneNumber,
  setRole,
} from "../../store/reducers/user.reducer";

export const Header: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { authStatus } = useAuthenticator();
  const isAuthenticated = authStatus === "authenticated";

  useEffect(() => {
    async function fetchUser() {
      const user = await fetchUserAttributes();
      dispatch(setEmail(user.email));
      dispatch(setSub(user.sub));
      dispatch(setFamilyName(user.family_name));
      dispatch(setGivenName(user.given_name));
      dispatch(setPhoneNumber(user.phone_number));
    }
    if (isAuthenticated) {
      fetchUser();
    }
  }, [isAuthenticated, dispatch]);

  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Check for small screens

  return (
    <Box>
      {/* <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="relative"
          sx={{ backgroundColor: theme.palette.primary.main }}
          elevation={0}
        >
          <Container>
            {isMobile ? null : <TopBar isAuthenticated={isAuthenticated} />}
          </Container>
        </AppBar>
      </Box> */}
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="relative"
          sx={{ backgroundColor: "#fff", borderBottom: "1px solid #e4e4e4" }}
          elevation={0}
        >
          <Container>
            <MainBar isAuthenticated={isAuthenticated} />
          </Container>
        </AppBar>
      </Box>
    </Box>
  );
};
