import React from "react";
import { Link } from "gatsby";
import {
  Grid,
  Box,
  Container,
  Typography,
  Button,
  useTheme,
  Link as MuiLink,
  useMediaQuery,
} from "@mui/material";

import { Social } from "../Social";
import { Logo } from "../Logo";
import { StyledFooter } from "./Footer.styled";
import { myAccountData, orderHistoryData, contactData } from "./Footer.api";

export const Footer: React.FC = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <StyledFooter>
      <Container>
        {/* <Grid container p={4} spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              color="white"
              align={isSmall ? "center" : "left"}
            >
              <strong>My Account</strong>
            </Typography>
            <Box>
              {myAccountData.map((item, index) => (
                <Box
                  display="flex"
                  justifyContent={isSmall ? "center" : "flex-start"}
                  key={index}
                >
                  <MuiLink
                    component={Link}
                    key={item.id}
                    to={item.route}
                    style={{ textDecoration: "none" }}
                    sx={{ color: "#fff" }}
                  >
                    {item.title}
                  </MuiLink>
                </Box>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle1"
              color="white"
              align={isSmall ? "center" : "left"}
            >
              <strong>Contact Us</strong>
            </Typography>
            <Box>
              {contactData.map((item, index) => (
                <Box
                  display="flex"
                  justifyContent={isSmall ? "center" : "flex-start"}
                  key={index}
                >
                  <MuiLink
                    component={Link}
                    key={item.id}
                    to={item.route}
                    style={{ textDecoration: "none" }}
                    sx={{ color: "#fff" }}
                  >
                    {item.title}
                  </MuiLink>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid> */}
        <Grid container py={4}>
          <Grid item xs={12} md={4}>
            <Box>
              <Typography align={isSmall ? "center" : "left"} color="white">
                © 2024 Comply Hub LLC All rights reserved.
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent={isSmall ? `center` : `flex-start`}
            >
              <Box>
                <MuiLink
                  component={Link}
                  to={`/privacy-policy`}
                  style={{ textDecoration: "none" }}
                  sx={{ color: "#fff" }}
                >
                  Privacy Policy
                </MuiLink>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent={`center`}>
              <Logo isWhite />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              display="flex"
              justifyContent={isSmall ? "center" : "flex-end"}
            >
              <Social />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </StyledFooter>
  );
};
