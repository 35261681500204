import React from 'react';
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
} from '@mui/material';
import { Dashboard as DashboardIcon } from '@mui/icons-material';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { navigation } from '../Header/Header.api'; // Adjust the import path as needed
import { DASHBOARD } from '../../constants/routes'; // Adjust the import path as needed

interface MobileDrawerProps {
  open: boolean;
  handleClose: () => void;
  customerName?: string;
  navigate: (route: string) => void;
  handleLogout: () => void;
}

export const MobileDrawer: React.FC<MobileDrawerProps> = ({
  open,
  handleClose,
  customerName,
  navigate,
  handleLogout,
}) => {
  const handleClickMobileNav = (route: string) => {
    navigate(route);
    handleClose();
  };

  const handleClickLogout = () => {
    handleLogout();
    handleClose();
  };
  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <Box width={250}>
        <List>
          <ListItem>
            <Typography variant="subtitle2" gutterBottom>
              Welcome, {customerName}!
            </Typography>
          </ListItem>
          <Divider />
          {navigation.map((item) => (
            <ListItem disablePadding key={item.id}>
              <ListItemButton onClick={() => handleClickMobileNav(item.route)}>
                <ListItemText secondary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleClickMobileNav(DASHBOARD)}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText secondary="Dashboard" />
            </ListItemButton>
          </ListItem>
        </List>

        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={handleClickLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText secondary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};
